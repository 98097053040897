import { createReducer } from '@reduxjs/toolkit';
import {
    loginRequest,
    loginSuccess,
    loginFailure,
    deleteRequest,
    deleteSuccess,
    deleteFailure,
    loginGoogleRequest,
    signupRequest,
    signupSuccess,
    signupFailure,
    checkEmailRequest,
    checkEmailSuccess,
    checkEmailFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetErrorRequest,
    loadingOnRequest,
    loadingOffRequest,
    logoutRequest,
    LoginResponse,
    SignupResponse,
    profileRequest,
    profileSuccess,
    profileFailure,
    profileUpdateRequest,
    profileUpdateSuccess,
    profileUpdateFailure,
    onboardingRequest,
    onboardingSuccess,
    onboardingFailure,
    EmailStatusResponse,
    SystemStatsResponse,
    systemStatsRequest,
    systemStatsSuccess,
    systemStatsFailure,
    constantsUpdateRequest,
    constantsResetRequest,
    constantsRequest,
    constantsSuccess,
    constantsFailure,
    feedbackRequest,
    feedbackSuccess,
    feedbackFailure,
    newslettersSubscribeRequest,
    newslettersSubscribeSuccess,
    newslettersSubscribeFailure,
    newslettersRequest,
    newslettersSuccess,
    newslettersFailure,
    NewsletterResponse,
} from './actions';


export interface AuthState {
    auth: LoginResponse | SignupResponse | null;
    stats: SystemStatsResponse | null;
    feedback: boolean | null;
    news: NewsletterResponse | null;
    is_email_valid: EmailStatusResponse | null;
    reset_password: boolean | null;
    loading: boolean;
    error: string | null;
    constants: Record<string, string|number|FileList> | null;
}

const initialState:AuthState = {
    auth: null,
    stats: null,
    news: null,
    constants: null,
    feedback: null,
    is_email_valid: null,
    reset_password: null,
    loading: false,
    error: null,
};

const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loginRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(loginSuccess, (state, action) => {
            state.loading = false;
            state.auth = action.payload;
            localStorage.setItem('authToken', action.payload.token);
        })
        .addCase(loginFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(profileRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(profileSuccess, (state, action) => {
            state.loading = false;
            state.auth = {...state.auth, 'user': action.payload } as LoginResponse;
        })
        .addCase(profileFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(profileUpdateRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(profileUpdateSuccess, (state, action) => {
            state.loading = false;
            state.auth = {...state.auth, 'user': action.payload } as LoginResponse;
        })
        .addCase(profileUpdateFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(onboardingRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(onboardingSuccess, (state, action) => {
            state.loading = false;
            state.auth = {...state.auth, 'user': action.payload } as LoginResponse;
        })
        .addCase(onboardingFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(deleteRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(deleteSuccess, (state) => {
            localStorage.removeItem('authToken');
            state.auth = null;
            state.error = null;
            state.loading = false;
        })
        .addCase(deleteFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(systemStatsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(systemStatsSuccess, (state, action) => {
            state.loading = false;
            state.stats = action.payload;
        })
        .addCase(systemStatsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(newslettersSubscribeRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(newslettersSubscribeSuccess, (state, action) => {
            state.loading = false;
            state.auth = {...state.auth, 'user': action.payload } as LoginResponse;
        })
        .addCase(newslettersSubscribeFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(newslettersRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(newslettersSuccess, (state, action) => {
            state.loading = false;
            state.news = action.payload;
            state.auth = {...state.auth, 'user': {...state.auth?.user, last_newsletter_id: action.payload.id}} as LoginResponse;
        })
        .addCase(newslettersFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(constantsUpdateRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(constantsResetRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(constantsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(constantsSuccess, (state, action) => {
            state.loading = false;
            state.constants = action.payload;
        })
        .addCase(constantsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(feedbackRequest, (state) => {
            state.loading = true;
            state.error = null;
            state.feedback = null;
        })
        .addCase(feedbackSuccess, (state, action) => {
            state.loading = false;
            state.feedback = action.payload;
        })
        .addCase(feedbackFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(loginGoogleRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(signupRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(signupSuccess, (state, action) => {
            state.loading = false;
            localStorage.setItem('authToken', action.payload.token);
            state.auth = action.payload;
        })
        .addCase(signupFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(checkEmailRequest, (state) => {
            state.loading = true;
            state.error = null;
            state.is_email_valid = null;
        })
        .addCase(checkEmailSuccess, (state, action) => {
            state.loading = false;
            state.is_email_valid = action.payload;
        })
        .addCase(checkEmailFailure, (state, action) => {
            state.loading = false;
            state.is_email_valid = null;
            state.error = action.payload;
        })
        .addCase(resetPasswordRequest, (state) => {
            state.loading = true;
            state.error = null;
            state.reset_password = null;
        })
        .addCase(resetPasswordSuccess, (state, action) => {
            state.loading = false;
            state.reset_password = action.payload;
        })
        .addCase(resetPasswordFailure, (state, action) => {
            state.loading = false;
            state.reset_password = null;
            state.error = action.payload;
        })
        .addCase(resetErrorRequest, (state) => {
            state.error = null;
            state.reset_password = null;
        })
        .addCase(loadingOnRequest, (state) => {
            state.loading = true;
        })
        .addCase(loadingOffRequest, (state) => {
            state.loading = false;
        })
        .addCase(logoutRequest, (state) => {
            localStorage.removeItem('authToken');
            state.auth = null;
            state.error = null;
            state.loading = false;
        });
});

export default authReducer;
