import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    removeProductRequest,
    removeProductSuccess,
    removeProductFailure,
    listProductsRequest,
    listProductsSuccess,
    listProductsFailure,
    addProductRequest,
    addProductSuccess,
    addProductFailure,
    resetErrorRequest,
    Product
} from './actions';

export interface ProductState {
    products: Product[];
    loading: boolean;
    error: string | null;
}

const initialState: ProductState = {
    products: [],
    loading: false,
    error: null,
};

const productReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeProductRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addProductRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listProductsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addProductSuccess, (state, action) => {
            state.products.push(action.payload); // Add product to the list
            state.loading = false;
        })
        .addCase(removeProductSuccess, (state, action: PayloadAction<{ id: number }>) => {
            state.products = state.products.filter(product => product.id !== action.payload.id);
            state.loading = false;
        })
        .addCase(listProductsSuccess, (state, action) => {
            state.products = action.payload; // Set products from the server
            state.loading = false;
        })
        .addCase(addProductFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(removeProductFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(resetErrorRequest, (state) => {
            state.error = null;
        })
        .addCase(listProductsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
});

export default productReducer;
