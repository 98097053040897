import { takeLatest, call, put } from 'redux-saga/effects';

import api from '../api';
import {
    removeProductRequest,
    removeProductSuccess,
    removeProductFailure,
    listProductsRequest,
    listProductsSuccess,
    listProductsFailure,
    addProductRequest,
    addProductSuccess,
    addProductFailure,
    buyProductRequest,
    BuyProductResponse,
    Product
} from './actions';

// API calls
const addProductApi = async (product: Product): Promise<Product> => {
    const response = await api.post("/api/products/", product, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
};

const removeProductApi = async (productId: number): Promise<void> => {
    await api.delete(`/api/products/${productId}`);
};

const buyProductApi = async (productId: number): Promise<void> => {
    const response = await api.post(`/api/products/${productId}/buy/`);
    return response.data;
};

const listProductApi = async (): Promise<Product[]> => {
    const response = await api.get('/api/products/');
    return response.data;
};

// Sagas
function* handleAddProduct(action: ReturnType<typeof addProductRequest>) {
    try {
        const data: Product = yield call(addProductApi, action.payload);
        yield put(addProductSuccess(data));
    } catch (error: any) {
        yield put(addProductFailure(error.response?.data || 'Failed to add product'));
    }
}

function* handleRemoveProduct(action: ReturnType<typeof removeProductRequest>) {
    try {
        yield call(removeProductApi, action.payload.id);
        yield put(removeProductSuccess(action.payload));
    } catch (error: any) {
        yield put(removeProductFailure(error.response?.data || 'Failed to remove product'));
    }
}

function* handleBuyProduct(action: ReturnType<typeof buyProductRequest>) {
    try {
        const data: BuyProductResponse = yield call(buyProductApi, action.payload.id);
        action.payload.cb(data)
        //yield put(removeProductSuccess(action.payload));
    } catch (error: any) {
        //yield put(removeProductFailure(error.response?.data || 'Failed to remove product'));
    }
}

function* handleListProduct() {
    try {
        const data: Product[] = yield call(listProductApi);
        yield put(listProductsSuccess(data));
    } catch (error: any) {
        yield put(listProductsFailure(error.response?.data || 'Failed to list products'));
    }
}

// Watcher sagas
export function* watchProductSaga() {
    yield takeLatest(addProductRequest.type, handleAddProduct);       // Listen for add product request
    yield takeLatest(removeProductRequest.type, handleRemoveProduct); // Listen for remove product request
    yield takeLatest(listProductsRequest.type, handleListProduct); // Listen for list products request
    yield takeLatest(buyProductRequest.type, handleBuyProduct); // Listen for list products request
}
