import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetErrorRequest as resetAuth } from '../redux/auth/actions';
import { resetErrorRequest as resetCategry } from '../redux/categories/actions';
import { resetErrorRequest as resetClips } from '../redux/clips/actions';
import { resetErrorRequest as resetProduct } from '../redux/products/actions';
import { useLocation } from 'react-router-dom';


const ErrorReset = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // Reset error whenever the route changes
        dispatch(resetAuth());
        dispatch(resetCategry());
        dispatch(resetClips());
        dispatch(resetProduct());
    }, [dispatch, location]);

    return null; // This component doesn't render anything
};

export default ErrorReset;
