import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    removeCategoryRequest,
    removeCategorySuccess,
    removeCategoryFailure,
    listCategoriesRequest,
    listCategoriesSuccess,
    listCategoriesFailure,
    addCategoryRequest,
    addCategorySuccess,
    addCategoryFailure,
    resetErrorRequest,
    Category
} from './actions';

export interface CategoryState {
    categories: Category[];
    loading: boolean;
    error: string | null;
}

const initialState: CategoryState = {
    categories: [],
    loading: false,
    error: null,
};

const categoryReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeCategoryRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addCategoryRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listCategoriesRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addCategorySuccess, (state, action) => {
            state.categories.push(action.payload); // Add category to the list
            state.loading = false;
        })
        .addCase(removeCategorySuccess, (state, action: PayloadAction<{ id: number }>) => {
            state.categories = state.categories.filter(category => category.id !== action.payload.id);
            state.loading = false;
        })
        .addCase(listCategoriesSuccess, (state, action) => {
            state.categories = action.payload; // Set categories from the server
            state.loading = false;
        })
        .addCase(addCategoryFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(removeCategoryFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(resetErrorRequest, (state) => {
            state.error = null;
        })
        .addCase(listCategoriesFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
});

export default categoryReducer;
