import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import App from './App.tsx';
import ProjectRoutes from './routes'
import { store, history, persistor } from './redux';
import { initSentry, config } from './config';
import { ErrorBoundary } from './components/ErrorBoundary';

import './styles/index.css';
import './styles/tailwind.css';

initSentry();
const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);


const Main = () => {
    return (
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                <Router history={history}>
                    <ProjectRoutes />
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </ErrorBoundary>
    );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <GoogleOAuthProvider clientId={config.google_auth_client_id}>
        <Elements stripe={stripePromise}>
            <React.StrictMode>
                <Main />
            </React.StrictMode>
        </Elements>
    </GoogleOAuthProvider>,
)
