import React, { Component, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { config } from "../config";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Check for specific error message related to dynamic imports
        if (error.message.includes("Failed to fetch dynamically imported module")) {
            return { hasError: true };
        }
        return { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // Log error details to an error reporting service if needed
        console.error("ErrorBoundary caught an error:", error, errorInfo);
    }

    componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
        if (this.state.hasError && !prevState.hasError) {
            if (config.env === "development") {
                console.error("ErrorBoundary caught an error");
            } else {
                // Optionally trigger a page reload or notify the user
                Sentry.captureException("ErrorBoundary caught an error");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }
        }
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // Display a fallback UI when an error occurs
            return <h1>Updating... Please wait.</h1>;
        }
        return this.props.children;
    }
}
