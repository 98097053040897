import { createAction } from '@reduxjs/toolkit';

// Define types for Product
export interface Product {
    title: string;
    id?: number;
    price: number;
    img: string;
    file?: string;
    link?: string;
    type?: string;
    has_bought?: number;
}

export interface BuyProductResponse {
    id?: string;
    url?: string;
};

// Define actions for products
export const removeProductRequest = createAction<{ id: number }>('products/removeProductRequest');
export const removeProductSuccess = createAction<{ id: number }>('products/removeProductSuccess');
export const removeProductFailure = createAction<string>('products/removeProductFailure');

export const addProductRequest = createAction<Product>('products/addProductRequest');
export const addProductSuccess = createAction<Product>('products/addProductSuccess');
export const addProductFailure = createAction<string>('products/addProductFailure');

export const listProductsRequest = createAction('products/listProductsRequest');
export const listProductsSuccess = createAction<Product[]>('products/listProductsSuccess');
export const listProductsFailure = createAction<string>('products/listProductsFailure');

export const resetErrorRequest = createAction('product/resetErrorRequest');
export const buyProductRequest = createAction<{ id: number, cb: any }>('product/buyRequest');
